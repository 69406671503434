// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-pillars-js": () => import("./../src/templates/Pillars.js" /* webpackChunkName: "component---src-templates-pillars-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-action-based-learning-consulting-js": () => import("./../src/pages/action-based-learning-consulting.js" /* webpackChunkName: "component---src-pages-action-based-learning-consulting-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fibromyalgia-chronic-pain-js": () => import("./../src/pages/fibromyalgia-&-chronic-pain.js" /* webpackChunkName: "component---src-pages-fibromyalgia-chronic-pain-js" */),
  "component---src-pages-gut-health-js": () => import("./../src/pages/gut-health.js" /* webpackChunkName: "component---src-pages-gut-health-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intuitive-eating-js": () => import("./../src/pages/intuitive-eating.js" /* webpackChunkName: "component---src-pages-intuitive-eating-js" */),
  "component---src-pages-mes-js": () => import("./../src/pages/mes.js" /* webpackChunkName: "component---src-pages-mes-js" */),
  "component---src-pages-mystory-js": () => import("./../src/pages/mystory.js" /* webpackChunkName: "component---src-pages-mystory-js" */),
  "component---src-pages-pricing-js": () => import("./../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-resources-js": () => import("./../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-smoothies-js": () => import("./../src/pages/smoothies.js" /* webpackChunkName: "component---src-pages-smoothies-js" */),
  "component---src-pages-specialties-js": () => import("./../src/pages/specialties.js" /* webpackChunkName: "component---src-pages-specialties-js" */),
  "component---src-pages-thank-you-js": () => import("./../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

